import React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import { Slides } from "../components/hero/Slides";
import { graphql } from "gatsby";
import { Layout } from "../components/layout/Layout";
import { Button } from "../components/ui/Button";
import { GatsbyImage } from "gatsby-plugin-image";
import { Map } from "../components/map/Map";
import clock from "../assets/images/icons/clock.svg";
import location from "../assets/images/icons/location.svg";

const IlNostroRistorante = ({ data }) => {
  const { wpSezione } = data;
  const { title, content, ilNostroRistorante } = wpSezione;
  const { herogallery, opacity, orari, image } = ilNostroRistorante;

  const DesktopView = () => (
    <div className="container pt-5 pb-5">
      <div className="row bg-white">
        <div className="col-md-6 d-flex flex-column">
          <div className="p-4 my-auto">
            <div className="row">
              <div className="col-2 nopadding text-end">
                <img
                  style={{
                    width: "32px",
                    height: "32px",
                    marginTop: "5px",
                  }}
                  src={clock}
                  alt="clock"
                />
              </div>
              <div className="col-10 nopadding">
                <h2 className="h2 text-primary text-uppercase">Orari</h2>
                <div
                  className="h5 text-secondary"
                  dangerouslySetInnerHTML={{ __html: orari }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              borderBottom: "5px solid #ce9532",
              width: "84%",
              margin: "0 auto",
            }}
          />
        </div>
        <div className="col-md-6 p-0">
          <GatsbyImage
            loading="eager"
            image={image.localFile.childImageSharp.gatsbyImageData}
            alt="orari"
          />
        </div>
      </div>
      <div className="row bg-white">
        <div className="col-md-6 d-flex flex-column">
          <div className="p-4 my-auto">
            <div className="row">
              <div className="col-2 nopadding text-end">
                <img
                  style={{
                    width: "32px",
                    height: "32px",
                    marginTop: "5px",
                  }}
                  src={location}
                  alt="location"
                />
              </div>
              <div className="col-10 nopadding">
                <h2 className="h2 text-primary text-uppercase">Indirizzo</h2>
                <p className="h5">
                  <a
                    href={"https://goo.gl/maps/nCcPAXFRuyLo5f7D6"}
                    className="text-decoration-none text-secondary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Viale Monte Grappa 3 - 42121 Reggio nell’Emilia (RE)
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 p-0">
          <Map />
        </div>
      </div>
      <div className="text-center mt-5 mb-3">
        <Link to="/prenota">
          <Button className="bg-primary text-white m-2">
            Prenota il tuo tavolo
          </Button>
        </Link>
      </div>
    </div>
  );

  const MobileView = () => (
    <div className="container pt-3 pb-5">
      <div className="row bg-white">
        <div className="p-3">
          <div className="col-12 nopadding text-center mb-3">
            <img
              style={{
                width: "64px",
                height: "64px",
              }}
              src={clock}
              alt="clock"
            />
          </div>
          <div className="col-12 nopadding text-center">
            <h2 className="h2 text-primary text-uppercase">Orari</h2>
            <div
              className="h5 text-secondary"
              dangerouslySetInnerHTML={{ __html: orari }}
            />
          </div>
        </div>
        <div className="col-md-6 p-0">
          <GatsbyImage
            loading="eager"
            image={image.localFile.childImageSharp.gatsbyImageData}
            alt="orari"
          />
        </div>
      </div>
      <div className="row bg-white">
        <div className="p-3 pb-5 pt-5">
          <div className="col-12 nopadding text-center mb-3">
            <img
              style={{
                width: "64px",
                height: "64px",
              }}
              src={location}
              alt="location"
            />
          </div>
          <div className="col-12 nopadding text-center">
            <h2 className="h2 text-primary text-uppercase">Indirizzo</h2>
            <p className="h5 text-secondary">
              <a
                href={"https://goo.gl/maps/nCcPAXFRuyLo5f7D6"}
                className="text-decoration-none text-secondary"
                target="_blank"
                rel="noopener noreferrer"
              >
                Viale Monte Grappa 3 - 42121 Reggio nell’Emilia (RE)
              </a>
            </p>
          </div>
        </div>

        <div className="col-md-6 p-0">
          <Map />
        </div>
      </div>
      <div className="text-center mt-5 mb-3">
        <Link to="/prenota">
          <Button className="bg-primary text-white m-2">
            Prenota il tuo tavolo
          </Button>
        </Link>
      </div>
    </div>
  );

  return (
    <main>
      <Helmet title={`${title} - Ristorante A Mangiare`} defer={false} />
      <Layout headerPosition="position-absolute" className="bg-white">
        <Slides
          title={title}
          content={content}
          opacity={opacity}
          images={herogallery}
        />
        <div className="d-none d-lg-block bg-tertiary">
          <DesktopView />
        </div>
        <div className="d-block d-lg-none bg-white">
          <MobileView />
        </div>
      </Layout>
    </main>
  );
};

export default IlNostroRistorante;

export const data = graphql`
  query {
    wpSezione(slug: { eq: "ristorante" }) {
      title
      content
      ilNostroRistorante {
        herogallery {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        opacity
        orari
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 420)
            }
          }
        }
      }
    }
  }
`;
