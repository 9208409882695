import React from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import { IS_BROWSER } from '../../utils';

export const Map = () => {
  const key = process.env.GATSBY_GOOGLE_MAPS_API;
  const [lat, lng] = [44.69516194227171, 10.6395370262833];
  const goToGoogleMaps = () => {
    if (IS_BROWSER) {
      window.open(`https://goo.gl/maps/oSASVUzpZTTiU1CHA`, '_blank');
    }
  };

  return (
    <div style={{ height: '350px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key }}
        options={{
          styles: [
            {
              featureType: 'all',
              elementType: 'labels',
              stylers: [
                {
                  visibility: '#on',
                },
              ],
            },
          ],
        }}
        center={{ lat, lng }}
        defaultZoom={16}
        yesIWantToUseGoogleMapApiInternals
      >
        <Marker lat={lat} lng={lng} text="" onClick={goToGoogleMaps} />
      </GoogleMapReact>
    </div>
  );
};
