import React from 'react';
import PropTypes from 'prop-types';
import { MdLocationPin } from 'react-icons/md';

const greatPlaceStyle = {
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
};

const Marker = ({ text, onClick }) => (
  <div style={greatPlaceStyle}>
    <MdLocationPin size={42} color={'#ce9532'} alt={text} onClick={onClick} />
  </div>
);

Marker.defaultProps = {
  onClick: null,
};

Marker.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default Marker;
